<template>
  <v-row no-gutters justify="center">
    <v-col xs="12" sm="10" md="8"> <!-- идеальное поведение-->
      <transactions/>
    </v-col>
  </v-row>
</template>

<script>
import transactions from "../site/modules/finance/user-table-transactions";


export default {
  components: {transactions},
}
</script>
